import axios, { AxiosResponse } from "axios";
import {
  ISearch,
  ISearches,
  ISearchFormValues,
  ISearchFormValuesSheetFields,
  ISearchResult,
  ISearchResults,
  ISearchSheetFieldsColumns,
  SearchStatusMatchToVariant,
} from "modules/Search/models";

const createSearch = (
  data: ISearchFormValues
): Promise<AxiosResponse<ISearch>> => axios.post("/inevitable/searches/", data);

const fetchSearches = (
  page: number,
  pageSize: number,
  search = "",
  type = "",
  status = ""
): Promise<AxiosResponse<ISearches>> =>
  axios.get(
    `/inevitable/searches/?page=${page}&page_size=${pageSize}&search=${search}${type}${status}`
  );

const fetchNotFailedSearches = (
  page: number,
  pageSize: number,
  search = ""
): Promise<AxiosResponse<ISearches>> =>
  axios.get(
    `/inevitable/searches/?page=${page}&page_size=${pageSize}&search=${search}&status=${SearchStatusMatchToVariant.queue}&status=${SearchStatusMatchToVariant.processing}&status=${SearchStatusMatchToVariant.processed}`
  );

const fetchAllSearches = (): Promise<AxiosResponse<ISearches>> =>
  axios.get(`/inevitable/searches/?page=1&page_size=1000`);

const fetchSearch = (id: number): Promise<AxiosResponse<ISearch>> =>
  axios.get(`/inevitable/searches/${id}`);

const fetchExistingSearches = (): Promise<AxiosResponse<ISearches>> =>
  axios.get(`/inevitable/searches/?page=1&page_size=1`);

const fetchSheetFields = (
  data: ISearchFormValuesSheetFields
): Promise<AxiosResponse<ISearchSheetFieldsColumns>> =>
  axios.post(`/inevitable/searches/sheet_fields_mapping/`, data);

const fetchSearchResults = (
  searchId: number,
  page: number,
  pageSize: number,
  search = "",
  campaignQuery: string,
  status = ""
): Promise<AxiosResponse<ISearchResults>> =>
  axios.get(
    `/inevitable/search_results/?page=${page}&page_size=${pageSize}&search_id=${searchId}&search=${search}${campaignQuery}${status}`
  );

const fetchCountSearchResults = (
  searchId: number
): Promise<AxiosResponse<ISearchResults>> =>
  axios.get(
    `/inevitable/search_results/?page=1&page_size=1&search_id=${searchId}`
  );

const fetchSearchResult = (id: number): Promise<AxiosResponse<ISearchResult>> =>
  axios.get(`/inevitable/search_results/${id}`);

const updateSearch = (
  id: number,
  data: ISearchFormValues
): Promise<AxiosResponse<ISearch>> => {
  return axios.patch(`/inevitable/searches/${id}/`, data);
};

const deleteSearch = (id: number): Promise<AxiosResponse<ISearch>> => {
  return axios.delete(`/inevitable/searches/${id}/`);
};

const deleteSearchResult = (
  id: number
): Promise<AxiosResponse<ISearchResult>> => {
  return axios.delete(`/inevitable/search_results/${id}/`);
};

const SearchService = {
  createSearch,
  fetchSearches,
  fetchAllSearches,
  fetchSearch,
  fetchSheetFields,
  fetchSearchResults,
  fetchExistingSearches,
  fetchCountSearchResults,
  fetchSearchResult,
  updateSearch,
  deleteSearch,
  deleteSearchResult,
  fetchNotFailedSearches,
};

export default SearchService;
